import { OrderDetail } from "./OrderDetail.model";
import { Action, ModalConfig, PageInitialLoad } from "./AppCommon.model";
import { Employee } from "./Employees.model";
import { AuthState } from "./Auth.model";

export interface Category {
    id?: string;
    userId: string;
    name: string;
    items: CategoryItem[];
}

export interface CategoryItem {
    id?: string;
    itemName: string;
    itemReceiptName?: string;
    itemPrice: number;
    commissionAmount?: number | null;
    commissionPercentage?: number | null;
    isVariablePrice?: boolean;
}

export interface CategoryItemModalValue extends CategoryItem {
    commission: number;
    isAmountCommission: boolean;
}

export interface SaleState {
    authState: AuthState;
    categories: Category[];
    orderDetail: OrderDetail;
    loggedInEmployee: Employee;
    isEditOrder?: boolean;
    pageInitialLoad?: PageInitialLoad;
}

export interface SaleLocalState {
    category: Category | null;
    categoryModalConfig: CategoryModalConfig;
    categoryItemModalConfig: CategoryItemModalConfig;
    showChooseFile: boolean;
}

export interface CategoryModalConfig extends ModalConfig {
    modalValue?: Category;
}

export interface CategoryItemModalConfig extends ModalConfig {
    modalValue?: CategoryItemModalValue;
    currentCategory?: Category | null;
}

export interface ConfirmationModalConfig extends ModalConfig {
    modalContentMessage: string;
}

export interface SaleActionCreators {
    getCategories(authState: AuthState): Action;
    addCategory(category: Category): Action;
    editCategory(category: Category): Action;
    deleteCategory(category: Category): Action;
    updateOrderDetail(orderDetail: OrderDetail): Action;
    importCategories(categories: Category[], authState: AuthState): Action;
    setIsEditOrder?(value: boolean): void;
    setPageInitialLoad?(value: PageInitialLoad): void;
}

export interface CategoryModalProps {
    categoryState: CategoryModalConfig;
    addCategory(category: Category): Action;
    editCategory(category: Category): Action;
    deleteCategory(category: Category): Action;
    handleModifyCategory(event: any): void;
    clearCategoryModal(): void;
}

export interface CategoryItemModalProps {
    categoryItemState: CategoryItemModalConfig;
    orderDetail: OrderDetail;
    isAmountCommission(categoryItem: CategoryItem): boolean;
    hasNoCommissionAssign(categoryItem: CategoryItem): boolean;
    handleModifyCategoryItemName(event: any): void;
    handleModifyCategoryItemReceiptName(event: any): void;
    handleModifyCategoryItemPrice(event: any): void;
    handleModifyCategoryItemCommission(event: any): void;
    handleModifyCommissionUnit(event: any, data: any): void;
    handleModifyIsVariablePrice(event: any, data: any): void;
    updateOrderDetail(orderDetail: OrderDetail): Action;
    clearCategoryItemModal(): void;
}

export interface ConfirmationModalProps {
    confirmationState: ConfirmationModalConfig;
    handleNoClick(): void;
    handleYesClick(): void;
}

export enum SaleEnum {
    addCategory = "addCategory",
    editCategory = "editCategory",
    addCategoryItem = "addCategoryItem",
    editCategoryItem = "editCategoryItem",
    modifyVariablePrice = "modifyVariablePrice"
}

export enum patternEnum {
    percentage = "percentage",
    amount = "amount"
}

export const patternOptions: any = [
    { key: patternEnum.amount, text: "$", value: patternEnum.amount },
    { key: patternEnum.percentage, text: "%", value: patternEnum.percentage }
]

export function emptyCategoryItems(category: Category): Category {
    category.items = [];
    return category;
}
