import { Modal, Button, Input, Dropdown, Form, Message, Icon, Radio } from 'semantic-ui-react';
import { CategoryItem, CategoryItemModalProps, CategoryItemModalValue, patternEnum, SaleEnum, patternOptions } from '../models/Sale.model';
import { useState } from 'react';
import ConfirmationModal from './Confirmation.modal';
import { modalTitles } from '../models/AppCommon.model';
import { useDispatch } from 'react-redux';
import { addCategoryItem, deleteCategoryItem, editCategoryItem } from '../actions/Sale.action';

function CategoryItemModal({ categoryItemState, orderDetail, isAmountCommission, hasNoCommissionAssign, handleModifyCategoryItemName, handleModifyCategoryItemReceiptName, handleModifyCategoryItemPrice, handleModifyCategoryItemCommission, handleModifyCommissionUnit, handleModifyIsVariablePrice, updateOrderDetail, clearCategoryItemModal }: CategoryItemModalProps) {
  const dispatch = useDispatch();

  const [categoryItemLocalState, setCategoryItemLocalState] = useState({
    confirmation: {
      modalOpen: false,
      modalType: "Remove Category Item Confirmation",
      modalContentMessage: "Are you sure you want to remove this category item?"
    },
    categoryItemValidation: {
      itemNameValidation: {
        isNotValid: false,
        message: "Please provide a Category Item Name"
      },
      itemPriceValidation: {
        isNotValid: false,
        message: "Please provide a Category Item Price"
      },
      itemCommissionValidation: {
        isNotValid: false,
        message: "Please provide a Category Item Commission"
      },
    },
    selectedCategoryItemModalValue: {} as CategoryItemModalValue
  });

  const showModalRemoveItem = () => {
    return categoryItemState.modalType === SaleEnum.editCategoryItem;
  }

  const getCategoryItem = (categoryItemModalValue: CategoryItemModalValue) => {
    const categoryItem: CategoryItem = {
      id: categoryItemModalValue.id,
      itemName: categoryItemModalValue.itemName,
      itemReceiptName: categoryItemModalValue.itemReceiptName,
      itemPrice: Number(categoryItemModalValue.itemPrice),
      commissionAmount: categoryItemModalValue.isAmountCommission ? Number(categoryItemModalValue.commission) : null,
      commissionPercentage: !categoryItemModalValue.isAmountCommission ? Number(categoryItemModalValue.commission) : null,
      isVariablePrice: categoryItemModalValue.isVariablePrice ? true : false
    }

    if (!categoryItem.id) {
      delete categoryItem.id;
    }

    return categoryItem;
  }

  const handleSave = (categoryItemModalValue: CategoryItemModalValue) => {
    const categoryItem: CategoryItem = getCategoryItem(categoryItemModalValue);
    if (categoryItemState.modalType === SaleEnum.modifyVariablePrice && isItemPriceValid()) {
      addItemToOrderDetail(categoryItem);
    } else if (categoryItemState.modalType !== SaleEnum.modifyVariablePrice && isItemNameValid() && isItemPriceValid() && isItemCommissionValid()) {
      if (categoryItemState.modalType === SaleEnum.editCategoryItem) {
        dispatch(editCategoryItem(categoryItemState.currentCategory!, categoryItem));
      } else {
        dispatch(addCategoryItem(categoryItemState.currentCategory!, categoryItem));
      }
    }
    if (isItemNameValid() && isItemPriceValid() && isItemCommissionValid()) {
      clearCategoryItemModal();
    }
  }

  const handleDelete = (categoryItemModalValue: CategoryItemModalValue) => {
    setCategoryItemLocalState(prevState => ({
      ...prevState,
      confirmation: {
        ...prevState.confirmation,
        modalOpen: true
      },
      selectedCategoryItemModalValue: categoryItemModalValue
    }));
  }

  const handleCancel = () => {
    clearCategoryItemModal();
  }

  const handleNoClick = () => {
    clearConfirmationModal();
  }

  const handleYesClick = () => {
    const categoryItem: CategoryItem = getCategoryItem(categoryItemLocalState.selectedCategoryItemModalValue);
    dispatch(deleteCategoryItem(categoryItemState.currentCategory!, categoryItem));
    clearCategoryItemModal();
    clearConfirmationModal();
  }

  const clearConfirmationModal = () => {
    setCategoryItemLocalState(prevState => ({
      ...prevState,
      confirmation: {
        ...prevState.confirmation,
        modalOpen: false
      }
    }));
  }

  const addItemToOrderDetail = (categoryItem: CategoryItem) => {
    updateOrderDetail({
      ...orderDetail,
      items: [...orderDetail.items, { ...categoryItem, quantity: 1 }]
    });
  }

  const isItemNameValid = () => {
    const isItemNameValid: boolean = categoryItemState.modalValue?.itemName as any !== "";
    setCategoryItemLocalState(prevState => ({
      ...prevState,
      categoryItemValidation: {
        ...prevState.categoryItemValidation,
        itemNameValidation: {
          ...prevState.categoryItemValidation.itemNameValidation,
          isNotValid: !isItemNameValid
        }
      }
    }));
    return isItemNameValid;
  }

  const isItemPriceValid = () => {
    const isItemPriceValid: boolean = categoryItemState.modalValue?.itemPrice as any !== "";
    setCategoryItemLocalState(prevState => ({
      ...prevState,
      categoryItemValidation: {
        ...prevState.categoryItemValidation,
        itemPriceValidation: {
          ...prevState.categoryItemValidation.itemPriceValidation,
          isNotValid: !isItemPriceValid
        }
      }
    }));
    return isItemPriceValid;
  }

  const isItemCommissionValid = () => {
    const isItemCommissionValid: boolean = categoryItemState.modalValue?.commission as any !== "";
    setCategoryItemLocalState(prevState => ({
      ...prevState,
      categoryItemValidation: {
        ...prevState.categoryItemValidation,
        itemCommissionValidation: {
          ...prevState.categoryItemValidation.itemCommissionValidation,
          isNotValid: !isItemCommissionValid
        }
      }
    }));
    return isItemCommissionValid;
  }

  const getValidationInfo = () => {
    for (const [key, value] of Object.entries(categoryItemLocalState.categoryItemValidation)) {
      if (value.isNotValid) {
        return value;
      }
    }
    return {
      isNotValid: false,
      message: ""
    }
  }

  return (
    <div>
      <Modal
        size="small"
        open={categoryItemState.modalOpen}
      >
        <Modal.Header>{modalTitles[categoryItemState.modalType]}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {getValidationInfo().isNotValid && <Message negative>
              <Message.Header>{getValidationInfo().message}</Message.Header>
            </Message>}
            {categoryItemState.modalType !== SaleEnum.modifyVariablePrice && <Form>
              <Form.Field>
                <label>Item Name</label>
                <Input
                  type="text"
                  value={categoryItemState.modalValue && categoryItemState.modalValue.itemName}
                  onChange={handleModifyCategoryItemName}
                />
              </Form.Field>
              <Form.Field>
                <label>Item Receipt Name</label>
                <Input
                  type="text"
                  value={categoryItemState.modalValue && categoryItemState.modalValue.itemReceiptName}
                  onChange={handleModifyCategoryItemReceiptName}
                />
              </Form.Field>
              <Form.Field>
                <label>Item Price</label>
                <Input
                  label={{ basic: true, content: "$" }}
                  labelPosition="left"
                  type="number"
                  value={categoryItemState.modalValue && categoryItemState.modalValue.itemPrice}
                  onChange={handleModifyCategoryItemPrice}
                  disabled={categoryItemState.modalValue && categoryItemState.modalValue.isVariablePrice}
                />
              </Form.Field>
              <Form.Field>
                <label>Item Commission</label>
                <Input
                  action={
                    <Dropdown
                      button
                      basic
                      floating
                      options={patternOptions}
                      defaultValue={hasNoCommissionAssign(categoryItemState.modalValue!) || isAmountCommission(categoryItemState.modalValue!) ? patternEnum.amount : patternEnum.percentage}
                      onChange={handleModifyCommissionUnit}
                    />
                  }
                  type="number"
                  value={categoryItemState.modalValue && categoryItemState.modalValue.commission}
                  onChange={handleModifyCategoryItemCommission}
                />
              </Form.Field>
              <Form.Field>
                <label>Variable Price Item</label>
                <Radio
                  toggle
                  checked={categoryItemState.modalValue?.isVariablePrice}
                  onChange={handleModifyIsVariablePrice}
                />
              </Form.Field>
            </Form>}

            {categoryItemState.modalType === SaleEnum.modifyVariablePrice && <Form>
              <Form.Field>
                <label>Item Price</label>
                <Input
                  label={{ basic: true, content: "$" }}
                  labelPosition="left"
                  type="number"
                  value={categoryItemState.modalValue && categoryItemState.modalValue.itemPrice}
                  onChange={handleModifyCategoryItemPrice}
                />
              </Form.Field>
            </Form>}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="teal" onClick={() => handleSave(categoryItemState.modalValue!)}>
            <Icon name="save" />
            Save
          </Button>
          <Button onClick={() => handleCancel()}>
            <Icon name="cancel" />
            Cancel
          </Button>
          {showModalRemoveItem() && <Button negative onClick={() => handleDelete(categoryItemState.modalValue!)}>
            <Icon name="delete" />
            Remove Item
          </Button>}
        </Modal.Actions>
      </Modal>
      <ConfirmationModal
        confirmationState={categoryItemLocalState.confirmation}
        handleNoClick={handleNoClick}
        handleYesClick={handleYesClick}
      />
    </div>
  );
}

export default CategoryItemModal;