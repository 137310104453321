import { Form, Dropdown, Message, Button, Icon, Grid } from 'semantic-ui-react';
import '../styles/Common.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { dateRangeOptions, orderStatusOptions, DateRangeValues, FilterProps, getStartDay, getEndDay } from '../models/AppCommon.model';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../actions/Orders.action';
import { RootState } from '../reducers/root.reducer';
import { setFilter } from '../actions/Filter.action';


function FilterComponent({ showOrderStatus, showRefresh, refreshEvent }: FilterProps) {
  const dispatch = useDispatch();

  const authState = useSelector((state: RootState) => state.auth);
  const filterState = useSelector((state: RootState) => state.filter);
  const employees = useSelector((state: RootState) => state.employees);

  useEffect(() => {
    dispatch(getOrders(authState, filterState, employees, showOrderStatus));
  }, [filterState.startDate, filterState.endDate, filterState.selectOrderStatus]);


  const handleStartDateChange = (date: Date) => {
    if (date) {
      const startDate = getStartDay(date);
      const endDate = getEndDay(date);
      dispatch(setFilter({ ...filterState, startDate: startDate, endDate: filterState.endDate === null ? endDate : filterState.endDate, selectRange: null }));
    }
  };

  const handleEndDateChange = (date: Date) => {
    if (date) {
      const startDate = getStartDay(date);
      const endDate = getEndDay(date);
      dispatch(setFilter({ ...filterState, startDate: filterState.startDate === null ? startDate : filterState.startDate, endDate: endDate, selectRange: null }));
    }
  };

  const handleDateRangeChange = (e: any, { value }: any) => {
    dispatch(setFilter({ ...filterState, selectRange: value }));
    calculateStartEndDate(value);
  };

  const handleOrderStatusChange = (e: any, { value }: any) => {
    dispatch(setFilter({ ...filterState, selectOrderStatus: value }));
  };

  const handleRefreshClick = () => {
    if (refreshEvent) {
      refreshEvent();
    }
  }

  const calculateStartEndDate = (value: any) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    if (value === DateRangeValues.today) {
      dispatch(setFilter({ ...filterState, startDate: new Date(year, month, day, 0, 0, 0, 0), endDate: new Date(year, month, day, 23, 59, 59, 999), selectRange: DateRangeValues.today }));
    } else if (value === DateRangeValues.yesterday) {
      dispatch(setFilter({ ...filterState, startDate: new Date(year, month, day - 1, 0, 0, 0, 0), endDate: new Date(year, month, day - 1, 23, 59, 59, 999), selectRange: DateRangeValues.yesterday }));
    } else if (value === DateRangeValues.aMonth) {
      let previousMonth = month - 1;
      let yearValue = year;
      if (previousMonth < 0) {
        previousMonth = 11;
        yearValue = year - 1;
      }
      dispatch(setFilter({ ...filterState, startDate: new Date(yearValue, previousMonth, day + 1, 0, 0, 0, 0), endDate: new Date(year, month, day, 23, 59, 59, 999), selectRange: DateRangeValues.aMonth }));
    } else if (value === DateRangeValues.aYear) {
      dispatch(setFilter({ ...filterState, startDate: new Date(year - 1, month, day + 1, 0, 0, 0, 0), endDate: new Date(year, month, day, 23, 59, 59, 999), selectRange: DateRangeValues.aYear }));
    } else if (value === DateRangeValues.all) {
      dispatch(setFilter({ ...filterState, startDate: null, endDate: null, selectRange: DateRangeValues.all }));
    }
  }

  const isStartDateAfterEndDate = () => {
    return filterState.startDate !== null && filterState.endDate !== null && (filterState.startDate.getTime() > filterState.endDate.getTime());
  }

  const showError = () => {
    return isStartDateAfterEndDate();
  }

  return (
    <div>
      {showError() && <Message negative>
        <Message.Header>The 'End Date' can not be set to an earlier date than the 'Start Date'.</Message.Header>
      </Message>}
      <Grid>
        <Grid.Column width={14}>
          <Form>
            <Form.Group inline>
              <Form.Field className="filter__width">
                <DatePicker
                  selected={filterState.startDate}
                  onChange={handleStartDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Start Date"
                />
              </Form.Field>
              <strong className="filter__dash">-</strong>
              <Form.Field className="filter__width">
                <DatePicker
                  selected={filterState.endDate}
                  onChange={handleEndDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="End Date"
                />
              </Form.Field>
              <div className="filter__width">
                <Dropdown
                  selection
                  className="filter__dropdown"
                  placeholder="Date Range"
                  value={filterState.selectRange}
                  onChange={handleDateRangeChange}
                  options={dateRangeOptions}
                />
              </div>
              {showOrderStatus &&
                <div className="filter__width">
                  <Dropdown
                    selection
                    className="filter__dropdown"
                    placeholder="Order Status"
                    value={filterState.selectOrderStatus}
                    onChange={handleOrderStatusChange}
                    options={orderStatusOptions}
                  />
                </div>}
            </Form.Group>
          </Form>
        </Grid.Column>
        {showRefresh && <Grid.Column width={1}>
          <Button icon onClick={() => handleRefreshClick()}>
            <Icon name="redo alternate" />
          </Button>
        </Grid.Column>}
      </Grid>
    </div>
  );
}

export default FilterComponent;