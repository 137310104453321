import { Dispatch } from 'react';
import { auth, db } from '../firebase/firebaseService';
import { FirebaseDatabaseNames, internetCheck } from '../models/AppCommon.model';
import { AuthAction, AuthState, initialAuthState } from '../models/Auth.model';
import { clearMessage, fetchData, updateErrorMessage } from './APIResponse.action';
import { decreaseLoader, increaseLoader } from './Loader.action';

// Action types
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

// Action creator
export function getUserById(user: any): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.users).doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    dispatch(fetchData(UPDATE_USER_INFO, {
                        isLoggedIn: true,
                        ...doc.data()
                    }));
                } else {
                    dispatch(updateErrorMessage("User not found"));
                }
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error getting user by Id: " + error.message));
            }).finally(() => {
                dispatch(decreaseLoader());
            });
    };
}


export function updateUserInfo(authState: AuthState): AuthAction {
    return {
        type: UPDATE_USER_INFO,
        payload: { ...authState }
    }
}

export function onLogout(): AuthAction {
    auth.signOut();
    return {
        type: UPDATE_USER_INFO,
        payload: initialAuthState
    }
}

export function updateUserRef(authState: AuthState, updateAuthReducer = false): any {
    const userRefAuthState = {
        ...authState
    }
    delete userRefAuthState.isLoggedIn;
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.users).doc(authState.id).set(userRefAuthState)
            .then(() => {
                if (updateAuthReducer) {
                    dispatch(fetchData(UPDATE_USER_INFO, { ...userRefAuthState }));
                }
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error updating user ref: " + error.message));
            }).finally(() => {
                dispatch(decreaseLoader());
            });
    };
}